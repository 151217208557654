<template>
  <v-simple-table class="border-bottom">
    <template>
      <thead class="blue-grey lighten-5">
        <tr class="text-caption grey--text ">
          <th v-if="isSuperAdmin" class="text-left font-weight-regular">
            Роль
          </th>
          <th v-if="isSuperAdmin" class="text-left font-weight-regular">
            Администратор
          </th>
          <th class="text-left font-weight-regular">
            Имя
          </th>
          <th class="text-left font-weight-regular">
            Email
          </th>
          <!-- <th class="text-left font-weight-regular">
            Шлем
          </th> -->
          <!-- <th class="text-left font-weight-regular">
            Группа
          </th> -->
          <th class="text-left font-weight-regular">
            Дата создания
          </th>
          <!-- <th class="text-left font-weight-regular">
            Дата последней тренировки
          </th> -->
          <!-- <th class="text-left font-weight-regular">
            Режим тренировки
          </th> -->
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="item in usersFilters" :key="item.id">
          <td v-if="isSuperAdmin">{{ item.role.name }}</td>
          <td v-if="isSuperAdmin">{{ item.doctor.name }}</td>
          <td>{{ item.name }}</td>
          <td>{{ item.email }}</td>
          <!-- <td>{{ item.helmet.name }}</td> -->
          <!-- <td>{{ item.group.deep_name }}</td> -->
          <td>{{ dateFormat(item.created_at) }}</td>
         <!--  <td>{{ dateFormat(item.training_at) }}</td> -->
          <!-- <td>{{ item.mode ? (item.mode == 'free' ? 'Свободный' : 'По программе') : '' }}</td> -->
          <td class="two-actions__width">
            <div class="d-flex">
              <v-tooltip top>
                <template #[`activator`]="{ on, attrs }">
                  <v-btn @click="edit(item.id)" icon v-bind="attrs" v-on="on">
                    <v-icon>mdi-pencil</v-icon>
                  </v-btn>
                </template>
                Редактировать
              </v-tooltip>
              <v-tooltip top>
                <template #[`activator`]="{ on, attrs }">
                  <v-btn @click="remove(item.id)"  icon v-bind="attrs" v-on="on">
                    <v-icon>mdi-trash-can</v-icon>
                  </v-btn>
                </template>
                Удалить
              </v-tooltip>
            </div>
          </td>
        </tr>
      </tbody>
    </template>
  </v-simple-table>
</template>

<script>
export default {
  props: {
    
  },
  computed: {
    users() {
      return this.$store.state.experts;
    },
    isAdmin() {
      return this.$store.getters.isAdmin;
    },
    isSuperAdmin() {
      return this.$store.getters.isSuperAdmin;
    },
    usersFilters() {
      return this.users.filter(u => u.id != this.$store.state.auth.user.id)
    }
  },
  methods: {
    
    remove(id) {
      this.$emit("remove",id);
    },
    edit(id) {
      this.$emit("edit",id);
    },
    dateFormat(date) {
      if (!date) {
        return '';
      }
      return this.$moment(date).format("DD.MM.YYYY HH:mm")
    },
  },
}
</script>

<style>

</style>