<template>
  <div>
    <v-row align="center" class="mb-2 justify-space-between">
      <v-col cols="12" sm="2">
        <v-btn dark color="teal" @click="openCreateForm" class="mt-3 mb-6">Добавить специалиста</v-btn>
      </v-col>
      <v-col cols="12" lg="2" md="4" sm="5">
          <v-select
            v-model="sort"
            :items="sortingItems"
            item-value="value"
            label="Сортировать по"
            dense
            hide-details
            color="teal"
            item-color="teal lighten-1"
          >
          <template  #[`selection`]="{ item }">
            <div class="d-flex w-100">
              {{ item.text }}
              <v-spacer/>
              <v-icon class="ml-2">{{ item.icon }}</v-icon>
            </div>
          </template>
          <template  #[`item`]="{ item }">
            <div class="d-flex w-100">
              {{ item.text }}
              <v-spacer/>
              <v-icon class="ml-2">{{ item.icon }}</v-icon>
            </div>
          </template>
        </v-select>
      </v-col>
    </v-row>
    <specialists-list :users="filtredUsers"  ref="list" @remove="confirmRemove" @edit="openEditForm"/>
    <!-- <v-btn class="mt-5" color="blue-grey lighten-3">Архив</v-btn> -->
    <remove-dialog :open="openRemoveDialog" @confirm="remove" @close="closeRemoveDialog">
      <template #title>Удалить специалиста?</template>
      <template #body>Вы действительно хотите удалить специалиста?</template>            
      <template #btn>Удалить</template>            
    </remove-dialog>
    <specialists-form :open="openForm" :editId="editId" @close="closeForm"/>
  </div>
</template>

<script>
 
import SpecialistsList from './SpecialistsList'
import SpecialistsForm from './SpecialistsForm'
import RemoveDialog from '../main/RemoveDialog'
export default {
  components: {
    SpecialistsList,
    SpecialistsForm,
    RemoveDialog
  },
  data: () => ({
    openForm: false,
    openRemoveDialog: false,
    removeId: null,
    editId: null,
    sort: '-created',
    sortingItems: [
      { value: 'doctor', text:'Имя специалиста', icon: 'mdi-sort-alphabetical-ascending'  },
      { value: '-doctor', text:'Имя специалиста', icon: 'mdi-sort-alphabetical-descending'  },
      { value: 'name', text:'Имя пользователя', icon: 'mdi-sort-alphabetical-ascending'  },
      { value: '-name', text:'Имя пользователя', icon: 'mdi-sort-alphabetical-descending'  },
      { value: 'created', text:'Дата создания', icon: 'mdi-sort-ascending'  },
      { value: '-created', text:'Дата создания', icon: 'mdi-sort-descending'  },
      { value: 'training', text:'Дата последней тренировки', icon: 'mdi-sort-ascending'  },
      { value: '-training', text:'Дата последней тренировки', icon: 'mdi-sort-descending'  },
    ],
  }),
  watch: {
    sort() {
      this.sortItems();
    },
  },
  computed: {
    filtredUsers() {
      return this.users.filter((u) => u.role.id != 1)
    },
    isAdmin() {
      return this.$store.getters.isAdmin;
    },
    users() {
      return this.$store.state.experts;
    },
  },
  methods: {
    sortItems() {
      switch (this.sort) {
        case '-created':
          this.users.sort((a,b) => (a.created_at < b.created_at) ? 1 : ((b.created_at < a.created_at) ? -1 : 0));
          break;
        case 'created':
          this.users.sort((a,b) => (a.created_at > b.created_at) ? 1 : ((b.created_at > a.created_at) ? -1 : 0));
          break;
        case '-training':
          this.users.sort((a,b) => (a.training_at < b.training_at) ? 1 : ((b.training_at < a.training_at) ? -1 : 0));
          break;
        case 'training':
          this.users.sort((a,b) => (a.training_at && a.training_at > b.training_at) ? 1 : ((b.training_at && b.training_at > a.training_at) ? -1 : 0));
          break;
        case '-name':
          this.users.sort((a,b) => (a.name < b.name) ? 1 : ((b.name < a.name) ? -1 : 0));
          break;
        case 'name':
          this.users.sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));
          break;
        case '-doctor':
          this.users.sort((a,b) => (a.doctor.name < b.doctor.name) ? 1 : ((b.doctor.name < a.doctor.name) ? -1 : 0));
          break;
        case 'doctor':
          this.users.sort((a,b) => (a.doctor.name > b.doctor.name) ? 1 : ((b.doctor.name > a.doctor.name) ? -1 : 0));
          break;
      
        default:
          break;
      }
    },
    openEditForm(id) {
      this.editId = id;
      this.openForm = true;
    },
    openCreateForm() {
      this.editId = null;
      this.openForm = true;
    },
    closeForm() {
      this.editId = null;
      this.openForm = false;
    },
    confirmRemove(id) {
      this.removeId = id;
      this.openRemoveDialog = true;
    },
    closeRemoveDialog() {
      this.removeId = null;
      this.openRemoveDialog = false;
    },
    remove() {
      let id = this.removeId;
      let url = `/users/${id}`;
      this.openRemoveDialog = false;
       this.$api.delete(url)
        .then(() => {
          this.$store.dispatch('getUserGroups');
          this.$store.dispatch('getExperts');
          this.$store.dispatch('addMessage', 'Спициалист удален')
        })
        .catch(error => {           
            this.$store.dispatch('addRequestError', error)
        })
    },
  }
}
</script>

<style>

</style>