<template>
  <div class="users">
    <page-title :title="'Специалисты'"></page-title>
    
    <v-card class="pa-3 rounded-0 flat custom__card">
      <specialists-tab></specialists-tab>        
    </v-card>
  </div>
</template>
<script>
import PageTitle from '../components/main/PageTitle'
import SpecialistsTab from '../components/users/SpecialistsTab'
export default {
  components: {
    PageTitle,
    SpecialistsTab
  },
  data: () => ({
    activeTab: 0,
  }),
}
</script>
<style lang="scss">
  .w-72 {
    width: 72px;
  }
</style>